import React, { ReactElement } from 'react'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import NotFound from 'components/NotFound'

export default function NotFoundPage(): ReactElement {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout className="not-found-page">
          <NotFound />
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
